 .row-container {
   height: 100%;
   background-color: #E5E5E5;
 }

 .header-registro{
  background: #011d41;
 }
 
 .login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 96px;
    padding: 15% 1em 20% 1em;
    margin: 0;
  }
  
  .login__logo {
    height: 96px;
    left: 10%;
    width: 100%;
  }
  
  .login__bg {
    background-image: url(../../assets/login/login_bg.png);
    background-size: cover;
    min-height: 100%;
    width: 100%;
  }

  .login__title{
    color:#186db6
  }

  .label-input{
    color:#186db6
  }
  
  .login-description {
    color: #fff;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .description-wrapper {
    width: 80%;
  }
  
  .description__title {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
  }
  
  .description__content {
    height: auto;
    line-height: 38px;
  }
  
  .register {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  
  .register button.btn.btn-primary,
  .register button.btn.btn-primary:active {
    width: 100%;
  }

  .form-group .form-label{
      font-size: 0.9em;
  }
 
  
  .card-container{
    padding: 1em 2em 1em 2em;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.03);
    margin: 1.2em 0em;
  }
 
  .external-links{
    margin-top: 1.2em;
    text-align: center;
  }
 
  .external-links a p{
   font-size: 0.9em;
 }