.ball_wrap {
  position: relative;
  margin: 150px;
  width: 90px;
}

.green_ball {
  background: #00c762;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-left: 1em;
  /* border: 3px solid #ccc; */
  position: relative;
}

.linea-gris-logo {
  width: 2px;
  height: 48px;
  background: #eeeeee;
}

.texto-sidebar {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #001c41;
  text-decoration-line: none;
}

.texto-sidebar:hover {
    text-decoration-line: none;
  }
