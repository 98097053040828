.status-dot {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}

.proposal-status {
	color: #6c757d;
	margin-left: 1%;
}
