.div-titulo-busqueda {
  padding: 33px;
}

.titulo-listado-busqueda {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
}

.tabla-busqueda {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  margin: 0 40px;
}

@media screen and (max-width: 700px) {
  .tabla-busqueda {
    margin: 0;
  }
}
