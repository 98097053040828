.card-container-votation {
  padding: 1em;
}

.card-votation {
  background-color: #f9fafc;
}

.card-items-container-votation {
  padding: 1em;
}

.card-container-img-votation {
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 94%;
  min-height: 250px;
  align-items: center;
  display: flex;
  background-color: rgb(0,0,0,0.05);
}

.card-img-votation {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  backdrop-filter: blur(5px) brightness(75%);
}

.card-text-description-votation {
  font-size: 0.9em;
  min-height: 230px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 18px;
}

.card-text-description-votation ul {
  padding-left: 1em;
}

.alert span {
  margin-left: 1em;
  color: black;
}

.card-buttons-container {
  display: inline-grid;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-right: 2em;
}

.card-buttons-container button {
  margin-bottom: 1em;
}

.responder {
  background-color: #0f69b4;
  color: white;
  width: 100%;
}

.ver {
  background-color: #e5edff;
  border-color: #e5edff;
  color: #0f69b4;
  width: 100%;
}

.titulo-tarjeta-votaciones {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #2475b9;
  margin-bottom: 18px;
}

.pregunta-tarjeta-votacion {
  margin-bottom: 18px;
}

.tabs-votaciones {
  border-bottom: none;
}

.tabs-votaciones .nav-link {
  border: none;
}

.div-votacion-contestada-tarjetas {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.div-votacion-contestada-tarjetas img {
  margin-right: 10px;
}

.div-votacion-contestada-tarjetas p {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0f69b4;
}

.col-imagen-votacion,
.col-imagen-votacion-movil {
  height: 267px;
}

.col-imagen-votacion-movil {
  display: none;
}

@media screen and (max-width: 700px) {

  .col-imagen-votacion {
    display: none;
  }
  .col-imagen-votacion-movil {
    display: block;
    
    margin: 16px;
    padding-left: 0;

  }
}
