/* .row {
   height: 100vh;
 } */

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 78px;
  margin-bottom: 138px;
  width: 100%;
  height: 96px;
}

.login__logo {
  height: 96px;
  left: 10%;
  width: 100%;
}

.login__bg {
  background-image: url(../../assets/login/login_bg.png);
  background-size: cover;
  min-height: fit-content;
  width: 100%;
}

.login-description {
  color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
}

.title-header h5 {
  color: #fff;
}

.submit {
  background-color: #1069b4;
  margin-top: 20px;
}

.description-wrapper {
  width: 80%;
}

.description__title {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
}

.description__content {
  height: auto;
  line-height: 38px;
}

.login {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.login button.btn.btn-primary,
.login button.btn.btn-primary:active {
  width: 100%;
  background-color: #e22c2c;
  border-color: #e22c2c;
}

.card-container {
  padding: 1em 2em 1em 2em;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.03);
  margin: 1.2em 0em;
}

.header {
  position: absolute;
  background: #011d41;
}

.external-links {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
}

.error-label {
  color: #e4434a;
  font-size: 0.9em;
  margin-bottom: 1em;
}

.external-links a {
  color: #1069b4;
  font-size: 16px;
}
