.social-media-div {
	margin: 0;

}

.social-media-div p {
	margin-bottom: 3%;
	color: #000000;
}

.social-media-icons {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.social-media-icons p{
	margin-bottom: 0;
}

.social-media-centered-text {
	text-align: center;
}

.social-media-margin {
	margin: 0 auto;
}

.custom-social-media-icons-mis-solicitudes-info {
	width: 100%;
	display: flex;
	justify-content: space-around;
}

.icon-social-media-container{
	background-color: #F9FAFC;
	/* margin: 1em; */
	padding: 0.5em;
	border-radius: 50%;
	color: #7F8792
}

.icon-social-media-container a svg{
	height: 20px;
	width: 20px;
	color: #7F8792;
}

.icon-social-media-container a svg:hover{
	color: blue
}