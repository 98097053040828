.carousel {
  height: 50vh;
}
.carousel-inner {
  height: inherit;
}
.img-carousel {
  height: inherit;
}

.carousel-caption {
  right: 5%;
  left: 5%;
  text-align: left;
  z-index: 2;
}

.cardItem {
  margin-bottom: 2em;
}

.imgContainer-card {
  height: 20rem;
}

.imgContainer-card img {
  height: inherit;
}

.cards-title {
  margin-top: 1.5em;
  margin-bottom: 1em;
}
.cards-text {
  margin-top: 1em;
  margin-bottom: 1.5em;
  line-height: 2em;
}
.cards-tags {
  margin-top: 1em;
  margin-bottom: 1em;
}
.badge-component-style {
  background: rgb(240, 243, 255);
  color: rgb(36, 117, 185);
  border-radius: 14px;

  padding: 8px 10px;
  margin: 1%
}
.bg-cover-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.carousel-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,001c41+50,001c41+100&0+0,0+0,0.65+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(1, 28, 65, 0.75) 20%,
    rgba(0, 28, 65, 0.65) 80%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(1, 28, 65, 0.75) 20%,
    rgba(0, 28, 65, 0.65) 80%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(1, 28, 65, 0.75) 20%,
    rgba(1, 28, 65, 0.8) 85%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6001c41',GradientType=0 ); /* IE6-9 */
  right: 0%;
  left: 0%;
  bottom: 0%;
  padding: 16px;
}

.div-titulo-proyecto-tendencia {
  display: flex;
  align-items: center;
}

.imagen-tarjetas-tendencias {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  backdrop-filter: blur(5px) brightness(75%);
}

#texto-descriptivo-tarjeta-proyecto-tendencias {
  overflow-y: auto;
  max-height: 80px;
}

.contenedor-inicio {
  margin: 25px 50px;
}

.div-tags-fecha-area-proyecto {
  margin-bottom: 16px;
  flex-wrap: wrap;
  width: 100%;
}

.div-tags-fecha-area-proyecto,
.div-tags-proyecto,
.div-fecha-proyecto,
.div-area-proyecto {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.div-area-proyecto {
  max-width: 100%;
}

.div-area-proyecto p {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.div-tags-fecha-area-proyecto p {
  margin-bottom: 0;
  margin-right: 19px;
}

.div-tags-fecha-area-proyecto img {
  margin-right: 10px;
}

@media screen and (max-width: 700px) {
  .contenedor-inicio {
    margin: 16px;
  }
}
