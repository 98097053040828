.propuestas-content-page {
  background: #f7f7f9;
  padding-bottom: 5%;
}

.h-20em {
  height: 20em;
}

.bg-contain-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 200px;
}

.bg-contain-image div {
  backdrop-filter: blur(5px) brightness(75%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.cards-content {
  display: flex;
  flex-wrap: wrap;
}

/* Style card component */

.card-width {
  width: 30%;
  margin: 1.66%;
}

.card-width:hover {
  text-decoration: none;
}

.card-width-under-link {
  width: 100%;
}

.propuestas-content-page {
  width: 88%;
  margin-left: 12%;
}

.card-big-title p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-start;
  color: #0f69b4;
  min-height: 44px;
}

.proposals-info-content .row-date-tags-share {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.row-date-tags-share p {
  margin-bottom: 0px !important;
}

.card-second-title {
  margin-top: 0.75rem;
  color: #1c1861;
}

.cards-content .card-body {
  padding: 16px;
}

.card-text-style {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #49505d;
  margin-bottom: 16px;
  min-height: 68px;
}

.subtitle-style {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #4d4d4d;
  margin-bottom: 16px;
}

.number-of-votes-style {
  color: #1c1861;
}

/* Style progress component */

.progress {
  background: #abe9c3;
}

.progress-bar {
  background-color: #3bd278 !important;
}

/* Styles for button component */

.button-vote,
.button-vote:hover,
.button-vote:visited {
  background: #2475b9;
  border-radius: 5px;
  width: 100%;
  outline: none;
  border-color: #2475b9;
  box-shadow: none;
}

.button-vote {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boton-ver-propuesta {
  justify-content: center;
  margin-bottom: 15px;
}

.button-vote:active,
.button-vote:focus,
.button-vote:focus-within {
  /*CHANGED FOR VIDEO DEMO*/
  border: 1px solid #2475b9;
  background-color: #2475b9 !important;
  border-radius: 5px;
  width: 100%;
  outline: none;
  border-color: #2475b9 !important;
  box-shadow: none !important;
}
.button-vote-disable {
  border: 1px solid #33ac2e;
  background-color: #33ac2e !important;
  border-radius: 5px;
  width: 100%;
  outline: none;
  border-color: #f7f7f9 !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress-bar-container {
  margin-bottom: 0;
}

/* Style badge component */

.badge-component-style {
  background: #d7ebfb;
  border-radius: 14px;
  color: #2475b9;
  padding: 8px 10px;
  margin: 1%;
}

.boton-SIAC {
  display: none;
}

.etiquetas-tarjetas-propuestas {
  height: 34px;
  overflow: hidden;
}

.emptyPropuestas {
  justify-content: center;
  align-content: center;
  text-align: center;
  height: 60vh;
}
.boton-propuesta-nueva {
  background: #0f69b4;
  border-radius: 5px;
  height: 40px;
  width: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #feffff;
  justify-content: space-evenly;
  align-items: center;
}

.boton-propuesta-nueva:hover {
  background: #0f69b4;
}

.boton-propuesta-nueva p {
  margin-bottom: 0;
  margin-right: 40px;
}

@media screen and (max-width: 800px) {
  .cards-content {
    flex-flow: column;
  }

  .card-width {
    width: 98%;
    margin: 1%;
  }

  .proposals-navigation {
    flex-flow: column;
  }

  .proposals-inputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .proposals-navigation .boton-nueva-propuesta {
    font-size: 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  .boton-nueva-propuesta img {
    margin-left: 0;
  }

  .proposals-new {
    width: fit-content;
  }

  .proposals-title-navigation {
    width: 100%;
    margin-bottom: 16px;
  }

  .proposals-search-input {
    flex-grow: 1;
    width: 50%;
  }
}
