.link-styles,
.link-styles:hover {
  color: black;
  text-decoration: none;
}

.info-tarjeta-accion {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
}

.div-imagen-tarjetas-acciones {
  width: 100%;
  height: 291px;
}

.div-imagen-tarjetas-acciones div {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  backdrop-filter: blur(5px) brightness(75%);
}

.card-info-div-enlace-votacion {
  width: 100%;
  background: #f9fafc;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.03);
  border-radius: 2px 2px 2px 0px;
  margin: 24px 0;
  padding: 24px;
}

.card-info-div-enlace-votacion p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #49505d;
}

.card-info-div-enlace-votacion button {
  margin-top: 16px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 18px;
  background: #0f69b4;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  transition: all 0.3s;
}

.card-info-div-enlace-votacion button:focus {
  outline: none;
  background: #48a3ee;
}

.card-info-div-enlace-votacion button p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #ffffff;
}

@media screen and (max-width: 700px) {
  .boton-comentar {
    font-size: 0;
  }
}

