:root {
  --gray100: #f9f9fb;
  --gray300: #eaedf3;
}

.navbar-w {
  /* position: fixed; */
  width: 100%;
  height: 72px;
  top: 0;
  left: 0;
  padding: 0;
  z-index: 300;
  border-bottom: 1px solid var(--gray300);
}
.navbar-w > div {
  /* height: 100%; */
  width: auto;
  padding: 10px 0;
  margin: 0;
  float: left;
  position: relative;
}

.navbar-logo {
  width: 238px !important;
}
.navbar-logo div {
  height: 100%;
}
.logo {
  margin: 0 20px;
}
.btn-menu {
  line-height: 51px;
  margin: 0 0 0 20px;
}

.navbar-actions {
  padding: 0;
  box-sizing: border-box;
}
.navbar-actions div {
  height: 100%;
  padding: 0;
}
.navbar-actions .navbar-list {
  margin-bottom: 0;
  margin-top: 0;
}
.navbar-list li {
  margin: 0 25px 0 0;
  height: auto;
}

.navbar-search {
  min-width: 80%;
  max-width: 100% !important;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
}
.navbar-search-div {
  height: 100%;
  background: var(--gray100);
  border: 1px solid var(--gray300);
  border-radius: 5px;
}
.navbar-search-div div {
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.navbar-button-circle {
  min-width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  background-color: #f9fbfc !important;
  margin: 0 8px !important;
}

.navbar-button-circle:focus {
  outline: none;
}

.navbar-button a .icon {
  height: 100%;
  width: 100%;
}

.navbar-info {
  padding: 12px 0px 0px 15px !important;
  float: right !important;
  right: 70px;
  position: absolute !important;
  width: 10em !important;
}
.navbar-info div {
  height: 2.5rem;
  width: 100%;
}
.navbar-info-name {
  margin: 0 10px;
  text-align: right;
  width: 100%;
}

.navbar-profile {
  padding: 10px 20px 10px 0 !important;
  right: 0;
  position: absolute !important;
}
.navbar-profile-picture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  /* background-color: blue; */
}

.navbar-mobile {
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  right: 0;
  padding: 10px;
  box-sizing: border-box;
  z-index: 300;
  border-top: 1px solid var(--gray300);
  background: white;
  display: none;
}
.navbar-mobile-list {
  width: 100%;
  height: 100%;
}

.navbar-mobile-list li {
  width: 25%;
}

/* .navbar-badge {
  position: absolute;
  top: 0.5em !important;
  right: -0.5em !important;
} */

.MuiBadge-anchorOriginTopRightRectangle {
  top: -20px !important;
  right: -10px !important;
}

.MuiBadge-colorSecondary {
  background-color: #3AD377 !important;;
}

#appBar {
  width: calc(100% - 256px);
  position: relative;
  background: #ffffff;
  color: #001c41;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row;
}

.div-appbar-izquierda {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#drawwer-button-header {
  display: none;
}

.div-appbar-derecha {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.searchbar-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-left: 28px;
}

.searchbar-header svg {
  width: 16px;
  margin-right: 10px;
}

.searchbar-header input {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  border: none;
}

.searchbar-header input::placeholder {
  color: #0f69b4;
  opacity: 0.5;
}

.searchbar-header input:focus {
  outline: none;
}

.nombre-profile-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.div-nombre-header {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  margin-left: 22px;
}

.div-nombre-header p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.03em;
  color: #000000;
  margin-bottom: 0;
}

#div-div-imagen-de-perfil {
  margin: 16px;
}

@media screen and (max-width: 960px) {
  #appBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  #drawwer-button-header {
    display: flex;
  }
}

@media screen and (max-width: 700px) {

  .searchbar-header {
    display: none;
  }

  .nombre-profile-header {
    display: none;
  }

  .div-nombre-header p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .btn-menu {
    display: block;
  }
  .navbar-actions,
  .navbar-search,
  .navbar-info {
    display: none;
  }
  .navbar-mobile {
    display: block;
  }
  .navbar-button-circle{
    margin: 4px !important;
  }
}

@media (min-width: 769px) {
  .btn-menu {
    display: none;
  }
  .navbar-w {
    display: block;
  }
}

@media (width: 1024px) {
  .navbar-actions,
  .navbar-search,
  .navbar-info {
    display: none;
  }
  .navbar-mobile {
    display: block;
  }

}
