.container-question-quiz{
    padding: 1em;
    margin-bottom: 1.5em;
    background-color: #F9FAFC;
}

.container-buttons-question-answers{
    margin-top: 1em;
    padding-left: 0;
}

.button-question-answers{
    width: 100%;
    color: #0F69B4;
}

.button-question-answers:hover,
.button-question-answers:active {
    background-color: #0F69B4;
    color: #ffffff
}