/* ------------ Tabs Action Table ------------ */

.nav-tabs .nav-link.active {
  border: none;
}

.tabs-action-table {
  border-bottom: none;
}

.tabs-action-table a {
  height: 48px;
}

.tab-span-action-table {
  width: 160px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;
  border-radius: 2px;
  padding-bottom: 8px;
}

.tab-span-action-table svg {
  margin-right: 10px;
  min-width: 20px;
}

/* -------------- Action Table --------------- */

#div-index-action-table {
  width: 100%;
}

.row-herramientas-action-table {
  margin-bottom: 8px;
  margin-top: -48px;
  min-width: calc(100% - 520px);
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.dropdown-filter {
  background-color: white;
  color: blue;
  border: 0;
  width: 100%;
}

.dropdown-filter-menu {
  min-width: 100% !important;
}

.action-icon-group.col {
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;
  padding: 0;
}

.search-action-table {
  width: fit-content;
  max-width: 214px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #feffff;
  border: 1px solid #f9fafc;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 2px;
}

.search-action-table .input-group-prepend {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-action-table .input-group-prepend svg {
  color: #a6acb3;
  width: 14px;
}

.search-action-table input.form-control {
  background: none;
  border: none;
}

#input-search-action-table {
  display: block;
  max-width: 100%;
}

.action-icon-group div, .settings-icon-mobile-action-table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 9px;
  color: #5b616a;
}

.action-icon-group div svg {
  width: 20px;
}

.action-icon-group div:hover {
  color: blue;
}
.action-icon-group div:active {
  color: #0808a2;
}

.settings-icon-mobile-action-table {
  display: none;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #7f8792;
  box-sizing: border-box;
  border-radius: 5px;
}
.labelCheck {
  margin-left: 1em;
}

.col-create-button-action-table {
  max-width: fit-content;
  padding: 0;
  margin-left: 16px;
}

.button-new {
  min-width: fit-content;
  height: 40px;
  background: #0f69b4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-new p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #feffff;
}

.button-new img {
  margin-right: 10px;
}

/* ------------------ Responsive ------------------ */
@media screen and (max-width: 1100px) {
  .row-herramientas-action-table {
    position: relative;
    bottom: 48px;
  }
}

@media screen and (max-width: 600px) {
  .tab-span-action-table {
    width: fit-content;
  }

  .button-new {
    right: 30px;
    justify-content: center;
  }

  .button-new p {
    display: none;
  }

  .button-new img {
    margin-right: 0;
  }

  .action-icon-group div{
    min-width: 40px;
    min-height: 40px;
    border-radius: 20px;
    margin: 0;
    background: #ffffff;
  }

  .action-icon-group .settings-icon-action-table {
    display: none;
  }

  .settings-icon-mobile-action-table {
    display: flex;
    right: 70px;
    background: #ffffff;
  }

  .settings-icon-mobile-action-table, .button-new{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    bottom: 60px;
  }

  .breadcrumb-section {
    margin: 16px;
    width: calc(100% - 90px);
  }


  .row-herramientas-action-table {
    margin-top: -48px;
    min-width: 100%;
  }

  .tabs-action-table {
    z-index: 3;
    position: relative;
    top: 48px;
    padding: 0px 10px;
  }

  .tabs-mobile-3 {
    width: 33%;
  }

  .search-action-table {
    max-width: 48%;
  }

}
