.div-titulo-navegacion-configuracion {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column;
  height: 91px;
  padding: 0 32px;
}

.p-navegacion-configuracion {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  opacity: 0.5;
  margin-bottom: 4px;
}

.p-titulo-configuracion {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
}

.contenedor-configuracion {
  margin: 0 32px;
  width: calc(100% - 64px);
  padding: 37px 34px 28px 34px;
  background: #ffffff;
  border: 1px solid #d7ebfb;
  box-sizing: border-box;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.03);
  border-radius: 2px;
}

.forms-configuracion {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
  border-bottom: 1px solid #f0f3ff;
  margin-bottom: 24px;
}

.seccion-3-configuracion {
  width: 33.3333%;
}

.seccion-6-configuracion {
  width: 66.6666%;
}

.labels-configuracion {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0f69b4;
  margin-bottom: 7px;
}

.inputs-configuracion {
  background: #f9f9fb;
  border: 1px solid #f9f9fb;
  box-sizing: border-box;
  border-radius: 5px;
  border: none;
  height: 40px;
  width: 95%;
  padding: 0 8px;
}

.inputs-configuracion:focus {
  outline: none;
}

.botones-guardar-configuracion {
  background: #2475b9;
  border-radius: 5px;
  height: 40px;
  border: none;
  width: 121px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.botones-guardar-configuracion:hover {
  background: #3187ce;
  transition: all 0.3s;
}

.botones-guardar-configuracion:focus {
  outline: none;
}

.botones-guardar-configuracion p {
  margin-bottom: 0;
}

.boton-guardar-avatar-configuracion {
  margin-top: 40px;
}

.div-avatares-configuracion {
  display: flex;
  align-items: flex-start;
  padding-bottom: 25px;
  border-bottom: 1px solid #f0f3ff;
  margin-bottom: 24px;
}

.div-recibir-notificaciones-correo-configuracion {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.div-recibir-notificaciones-correo-configuracion label {
  width: 66.6666%;
  margin-bottom: 32px;
}

.toggle-button-configuracion {
  border-radius: 50px;
  width: 46px;
  height: 24px;
  border: none;
  display: flex;
  align-items: center;
  padding: 0 4px;
  transition: all 0.3s;
}

.toggle-button-configuracion:focus {
  outline: none;
}

.bolita-blanca-toggle-button-configuracion {
  background: #ffffff;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  position: relative;
  transition: all 0.3s;
}

.icono-avatar-configuracion,
.icono-avatar-seleccionado-configuracion {
  box-sizing: content-box;
  max-height: 46px;
  max-width: 46px;
  border-radius: 100%;
  margin: 10px;
  padding: 1px;
}

.icono-avatar-configuracion:hover,
.icono-avatar-seleccionado-configuracion {
  border: #3187ce 6px solid;
  transition: all 0.2s;
  margin: 4px;
  
}

.div-div-avatares-configuracion {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .contenedor-configuracion {
    margin: 0 10px;
    width: calc(100% - 20px);
  }

  .forms-configuracion,
  .div-avatares-configuracion,
  .div-recibir-notificaciones-correo-configuracion {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .seccion-3-configuracion,
  .seccion-6-configuracion {
    width: 100%;
  }
}
