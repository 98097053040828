#input-etiquetas-crear-propuesta {
  background: none;
  border: none;
}

#header-propuesta {
  padding: 24px 32px;
}

#regresar-propuesta {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-decoration-line: underline;
  color: #0f69b4;
  opacity: 0.5;
}

#regresar-propuesta p {
  margin-bottom: 0;
}

#regresar-propuesta svg {
  height: 16px;
}

#div-header-titulo-botones-propuesta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#titulo-propuesta {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  margin-bottom: 0;
}

#div-header-botones-propuesta {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.boton-enviar-propuesta-detalle {
  background: #0f69b4;
  border-radius: 5px;
  height: 40px;
  width: 124px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #feffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.boton-enviar-propuesta-borrador {
  background: #0f69b4;
  border-radius: 5px;
  height: 40px;
  width: 164px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #feffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 10px;
}
.boton-enviar-propuesta-borrador p {
  margin-bottom: 0;
  margin: initial;
}

.boton-enviar-propuesta-detalle p {
  margin-bottom: 0;
  margin: initial;
}

#datos-propuesta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 32px;
  margin-bottom: 16px;
}

#form-propuesta {
  background: #ffffff;
  padding: 24px;
  width: 100%;
}

.secciones-propuesta {
  margin-bottom: 34px;
}

.secciones-propuesta .ql-container.ql-snow,
.secciones-propuesta .ql-toolbar.ql-snow {
  border: none;
  background: #f9fafc;
}

.secciones-propuesta .ql-container.ql-snow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.secciones-propuesta .ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.labels-propuesta {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0f69b4;
}

.inputs-propuesta {
  background: #f9fafc;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  color: #4d4d4d;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  height: 40px;
}

#boton-subir-imagen-propuesta {
  background: #d7ebfb;
  color: #0f69b4;
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
}

#boton-subir-imagen-propuesta p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80%;
}

#boton-subir-imagen-propuesta svg {
  width: 12px;
}
#subir-imagen-propuesta {
  display: none;
}

#div-etiquetas-crear-propuesta {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.input-crear-propuesta {
  background: #f9fafc;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
}

#div-solo-etiquetas-crear-propuesta {
  display: flex;
  align-items: center;
  height: 100%;
}

.etiquetas-propuesta {
  height: 28px;
  background: #d7ebfb;
  border-radius: 14px;
  padding: 0 10px;
  margin-bottom: 0;
  margin-left: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0f69b4;
}

.secciones-propuesta .quill,
.secciones-propuesta .ql-editor,
.secciones-propuesta .quill .ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 240px;
}

.modal-propuesta-enviada {
  background: #f9fafc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 680px;
  height: 472px;
  padding: 24px;
}

.modal-propuesta-enviada:focus {
  outline: none;
}

.header-modal-propuesta-enviada p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  margin-bottom: 0;
}

.body-modal-propuesta-enviada {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: calc(100% - 58px);
}

.body-modal-propuesta-enviada p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #011c41;
  margin-bottom: 0;
}

.footer-modal-propuesta-enviada {
  display: flex;
  justify-content: flex-end;
}

.footer-modal-propuesta-enviada button {
  background: #1387da;
  border-radius: 5px;
  width: 235px;
  height: 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #feffff;
  border: none;
  display: flex;
  justify-content: center;
}

.footer-modal-propuesta-enviada button:focus {
  outline: none;
}

.inputs-propuesta option,
select.inputs-propuesta.form-control {
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  #div-header-botones-propuesta {
    width: 100%;
  }
  #div-header-titulo-botones-propuesta {
    flex-flow: column;
  }
  #header-propuesta {
    padding: 16px;
  }
  #titulo-propuesta {
    padding: 0 78px 16px 0;
    width: 100%;
    text-align: left;
  }
  #form-propuesta {
    padding: 16px;
  }

  .modal-propuesta-enviada {
    width: 90%;
    height: 90vh;
  }
}
