/* text colors */

:root {
  --title-color: #1d1960;
  --description-color: #8987ad;
  --box-text-color: #818181;
  --active-person-color: #d5d5f9;
  --chat-person-color: #474747;
  --chat-main-color: #2475b9;
  --time-color: #2475b9;
  --list-section-color: #f5f6fc;
  --person-section-color: #c5c4f7;
  --searchForm-section-color: #dbeaf7;
}

.chat-mensajes-enviados {
  background: #2475b9;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
}

.chat-mensajes-recibidos {
  background: #feffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
}

.texto-chat-mensajes-enviados p,
.texto-chat-mensajes-recibidos p {
  margin-bottom: 0;
}

.quill-chat:focus {
  outline: none;
}

.tabs-chat .nav-link.active {
  color: #0f69b4;
  border: none;
}

.tabs-chat .nav-link {
  color: #a6acb3;
  border: none;
  margin-bottom: 0;
}

.tabs-chat a {
  width: 50%;
}

#boton-drawer-lista-chat {
  display: none;
}

#boton-drawer-lista-chat:focus {
  outline: none;
}

#lista-chats-conversaciones {
  right: 0;
}

#boton-enviar-chat {
  background: #0f69b4;
}

.label-filtros {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2475b9;
}

.input-filtros {
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  height: 40px;
}

.div-botones-filtros {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.boton-borrar-filtros,
.boton-aplicar-filtros {
  border-radius: 5px;
  border: none;
  height: 36px;
  width: 131px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
}

.boton-borrar-filtros {
  background: #d7ebfb;
  color: #2475b9;
  margin-right: 8px;
}

.boton-aplicar-filtros {
  background: #1387da;
  color: #feffff;
}

.div-card-img-top-custom{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px) brightness(75%);
}

.card-img-top-custom {
  width: auto;
  height: 100%;
  cursor: pointer;
}


@media screen and (max-width: 700px) {
  #lista-chats-conversaciones {
    position: absolute;
    right: -325px;
    transition: all 0.3s;
    height: calc(100vh - 155px);
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
    overflow-y: scroll;
    z-index: 2;
  }

  #boton-drawer-lista-chat {
    display: block;
    background: #ffffff;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  #header-busqueda-chat {
    display: none;
  }

  #toolbar .ql-bold,
  #toolbar .ql-italic,
  #toolbar .ql-underline {
    display: none;
  }

  .quill-chat {
    padding-right: 20px !important;
  }

  #boton-enviar-chat {
    font-size: 0;
    width: 36px;
    min-width: 36px;
    height: 36px;
    border-radius: 18px;
    padding: 0;
  }

  #boton-enviar-chat span {
    margin: 0;
  }
}
