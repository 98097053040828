#mini-panel-usuario {
  display: flex;
  flex-flow: column;
  width: 285px;
  background: #feffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  padding: 16px;
  position: absolute;
  right: 8px;
  top: 80px;
  transition: all 0.3s;
}

#mini-panel-usuario #mini-panel-date {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  margin-bottom: 18px;
}

#mini-panel-usuario div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
}

#mini-panel-usuario p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #001c41;
  margin-bottom: 0;
}

#mini-panel-usuario svg {
  color: #0f69b4;
  width: 20px;
  margin-right: 10px;
}

#mini-panel-usuario button {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  border-radius: 5px;
  border: none;
  margin: 4px 0;
}

#mini-panel-usuario button:focus {
  outline: none;
}

#mini-panel-boton-configuracion {
  background: #0f69b4;
}

#mini-panel-boton-configuracion p,
#mini-panel-boton-configuracion svg {
  color: #ffffff;
}

#mini-panel-boton-logout {
  background: #d7ebfb;
  color: #0f69b4;
}
