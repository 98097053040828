.icon{
    height: 2em;
    /* width: 100% !important; */
    color: #2167a0
}

.load-more-container {
    padding-top: 2em;
    padding-bottom: 2em;
}

.load-more {
    text-align: center;
    width: 50%;
    margin: auto;
    word-wrap: break-word;
    color: #0F69B4;
}

.load-more:hover {
    cursor: pointer;
    color: #1387DA;
}

.container-information {
    margin-right: 3em !important;
    margin-left: 2.5em !important;
}

.titulo-table-actions {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: #0f69b4;
}

.breadcrumb-section {
    margin: 1.5em 0em;
}