.confirmation-style-modal {
	background: #f4f6fc;
}

.custom-style-new-proposal-button {
	width: 30% !important;
	margin: 0px 1% 0px 69%;
}

.custom-style-similar-proposal-button {
	width: 20% !important;
	background: #6fcf97 !important;
	border-color: #6fcf97 !important;
}

.custom-style-similar-proposal-button,
.custom-style-similar-proposal-button:hover,
.custom-style-similar-proposal-button:visited {
	background: #6fcf97;
	width: 20%;
	border-radius: 5px;
	outline: none;
	border-color: #6fcf97;
	box-shadow: none;
}

.custom-style-similar-proposal-button:active,
.custom-style-similar-proposal-button:focus,
.custom-style-similar-proposal-button:focus-within {
	border: 1px solid #6fcf97;
	background: #6fcf97 !important;
	border-radius: 5px;
	width: 20%;
	outline: none;
	border-color: #6fcf97 !important;
	box-shadow: none !important;
}

.similar-proposals-footer-style {
	background: #f4f6fc;
	border-top: none;
}

.custom-save-button-similar-proposal {
	width: 50% !important;
}

.close-button-similar-proposal-style,
.close-button-similar-proposal-style:hover,
.close-button-similar-proposal-style:focus,
.close-button-similar-proposal-style:focus-within,
.close-button-similar-proposal-style:active {
	color: #2475B9 !important;
	border-color: #2475B9 !important;
	background-color: #f4f6fc !important;
	border: none !important;
}

.close-button-similar-proposal-style:focus {
	box-shadow: none !important;
}
