.date-filter-wrapper {
    display: block;
    width: 100%;
}

.date-filter-input {
    width: 100%;
    border: 1px solid #d5deff;
    border-radius: 5px;
    background-color: #ffffff;

    height: 40px;
    padding: 12px;
}

.date-filter-popper {
    min-width: 340px;
}

.date-filter-popper .react-datepicker__month-container {
    min-width: 340px;
}

.date-filter-popper .react-datepicker__header {
    background-color: #fff;
    padding: 16px;
    padding-bottom: 6px;
    border-bottom: none;
}

.date-filter-popper .react-datepicker__triangle {
    display: none;
}

.date-filter-custom-header {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding-bottom: 16px;
}

.date-filter-custom-header select {
    width: 47%;
    height: 40px;

    background: #f4f6fc;

    border: 1px solid #d5deff;
    border-radius: 5px;

    padding: 12px;

    /* Typography */
    font-size: 14px;
    color: #001c41;
}

.date-filter-popper .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
}

.date-filter-popper .react-datepicker__day-name {
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    text-transform: uppercase;
    font-weight: 400;
}

.date-filter-popper .react-datepicker__month {
    margin: 0;
    padding: 16px;
}

.date-filter-popper .react-datepicker__week {
    display: flex;
    justify-content: space-between;
}

.date-filter-popper .react-datepicker__day {
    width: 40px;
    height: 40px;

    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;
    line-height: 15px;
    color: #1e2027;
}

.date-filter-popper .react-datepicker__day:hover {
    border-radius: 40px;
}

.date-filter-popper .react-datepicker__day.react-datepicker__day--selected,
.date-filter-popper .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: #2475b9;
    box-shadow: 0px 2px 8px rgba(63, 64, 240, 0.2);
    border-radius: 40px;
    color: #ffffff;
}

.date-filter-popper .react-datepicker__day.react-datepicker__day--disabled {
    color: #aab1c2;
}
