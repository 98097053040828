.titulo-acciones-del-proyecto {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #2475b9;
  margin-top: 32px;
  margin-bottom: 18px;
}

.titulo-acciones-en-proyectos {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #2475b9;
  margin-bottom: 18px;
}

.fecha-area-accion-en-proyecto {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #4d4d4d;
  margin-bottom: 16px;
}

.fecha-area-accion-en-proyecto img {
  margin-right: 10px;
}

.fecha-area-accion-en-proyecto p {
  margin-right: 20px;
  margin-bottom: 0;
}

.contenido-accion-en-proyecto,
.div-comentarios-encuesta-accion-en-proyecto {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  display: flex;
  align-items: center;
  color: #49505d;
  margin-bottom: 0;
}

.div-comentarios-encuesta-accion-en-proyecto div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.div-comentarios-encuesta-accion-en-proyecto-movil {
  display: none;
}

.div-comentarios-encuesta-accion-en-proyecto a {
  text-decoration-line: underline;
}

.div-comentarios-encuesta-accion-en-proyecto p {
  margin-bottom: 0;
  margin-right: 20px;
}

.div-comentarios-encuesta-accion-en-proyecto img {
  margin-right: 10px;
}

@media screen and (max-width: 700px) {
  .div-comentarios-encuesta-accion-en-proyecto-movil {
    display: flex;
    flex-flow: column;
  }

  .div-comentarios-encuesta-accion-en-proyecto-movil div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 8px 0;
  }

  .div-comentarios-encuesta-accion-en-proyecto-movil p {
    margin-bottom: 0;
  }

  .div-comentarios-encuesta-accion-en-proyecto {
    display: none;
  }

  /* .cuerpo-tarjeta-accion .row{
    height: 490px;
  } */
}
