.contendor-modal-resrequest {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background: #f9fafc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 32px;
}

.contendor-modal-resrequest:focus {
  outline: none;
}

.contendor-modal-resrequest p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #2475b9;
}

.contendor-modal-resrequest button {
  background: #1387da;
  border-radius: 5px;
  padding: 0 18px;
  height: 40px;
  color: #ffffff;
  border: none;
}

.div-loading-modal-resrequest,
.div-resultado-modal-resrequest {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.icono-rotate-modal-resrequest {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

