.comments-section {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}

.comment-content {
  display: flex;
  background: #f9f9fb;
  padding: 2%;
}

.comment-style {
  width: -webkit-fill-available;
}

.comment-image {
  margin-right: 2%;
}

.span-style {
  margin-bottom: 1%;
}

.user-style {
  font-weight: bold;
  margin-right: 2%;
}

.date-style {
  color: #bdbdbd;
}

.points-icon {
  float: right;
  width: 10px;
}

.comment-actions-container {
  display: inline-flex;
  float: right;
}

.comment-action {
  margin-right: 2.5em;
  display: inline-flex;
}

.comment-action svg {
  margin-right: 0.5em;
}

.total-likes {
  border-left-style: solid;
  border-left-color: black;
  padding-left: 1em;
}

.div-opciones-comentarios {
  position: relative;
  width: 100px;
  background: #feffff;
  border: 1px solid #f9fafc;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.div-opciones-comentarios p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.div-opciones-comentarios p:hover {
  text-decoration-line: underline;
}

.div-div-opciones-comentarios {
  float: right;
}

.p-opcion-editar-comentarios {
  color: #2475b9;
}

.p-opcion-eliminar-comentarios {
  color: #e3363e;
}

.p-responder-comentario {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #0f69b4;
  margin-bottom: 0;
  cursor: pointer;
}

.p-responder-comentario:hover {
  text-decoration-line: underline;
}

.textarea-edit-commment {
  width: 90%;
  background: #f9f9fb;
  border: 1px solid #1387da;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #49505d;
  box-sizing: border-box;
  border-radius: 5px;
}

.button-guardar-editar-comentario {
  background: #2475b9;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  height: 40px;
  padding: 0 8px;
}

.button-guardar-editar-comentario:focus {
  outline: none;
}

.div-edicion-comentario {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.mensaje-respondiendo-comentario {
  background: #d7ebfb;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  padding: 5px 15px;
  width: fit-content;
  display: flex;
  align-items: center;
  color: #2475b9;
}

.mensaje-respondiendo-comentario p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
}

.mensaje-respondiendo-comentario svg {
  width: 20px;
  margin: 0 0 0 5px;
  cursor: pointer;
}

.write-comment-div {
  display: flex;
}

.div-banner-comentario-rojo {
  background: rgba(227, 54, 62, 0.2);
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #4a4a4a;
}

.div-banner-comentario-rojo p {
  margin-bottom: 0;
}

.div-banner-comentario-rojo svg {
  color: #f7c137;
  min-width: 20px;
  margin: 0 12px;
}

@media screen and (max-width: 700px) {
  .comment-image {
    width: 10%;
  }

  .comment-style {
    width: 90%;
  }

  .comment-image img {
    width: 100%;
  }

  .name-date-comments {
    display: flex;
    flex-direction: column;
  }

  .comment-content,
  .div-banner-comentario-rojo p,
  .button-guardar-editar-comentario,
  .textarea-edit-commment {
    font-size: 12px;
  }

  .p-responder-comentario {
    margin-bottom: 20px
  }
}
