.proposals-navigation {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 33px;
}
.proposals-title-navigation {
  width: 40%;
}

.proposals-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  margin-bottom: 0;
}

.proposals-inputs {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.proposals-navigation input,
.proposals-navigation button {
  font-size: 14px;
}

.proposals-filters-input,
.proposals-search-input,
.proposals-new {
  margin-right: 2%;
  width: 30%;
}

.proposals-paragraph {
  margin-bottom: 0rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-decoration-line: underline;
  color: #0f69b4;
  opacity: 0.5;
}

.proposals-paragraph svg {
  width: 16px;
}

.proposals-image-after-style {
  vertical-align: baseline;
  margin-left: 7%;
}

.style-icon-in-input {
  background: white;
}

.style-input-proposals {
  border-left: none;
  padding-left: 0px;
}

.style-input-proposals:focus {
  outline: none !important;
  box-shadow: none;
  border: 1px solid #ced4da;
  border-left: none;
}

.custom-show-button-filtros-style,
.custom-show-button-filtros-style:hover,
.custom-show-button-filtros-style:active,
.custom-show-button-filtros-style:focus,
.custom-show-button-filtros-style:focus-within {
  width: 100%;
  background-color: white !important;
  color: #6c757d !important;
  border: 1px solid #ced4da;
  border-color: #ced4da !important;
  text-align: left;
  outline: none !important;
  box-shadow: none !important;
}

.custom-show-button-filtros-style img {
  margin-right: 17px;
}

.modal-filtros .modal-dialog {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-rojo-propuestas {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  align-items: flex-start;
  background: #ffebeb;
  border-radius: 2px;
  width: 96%;
  margin: auto;
}

.banner-rojo-propuestas img {
  margin: 0 12px;
}

.banner-rojo-propuestas p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #e3363e;
  margin-bottom: 0;
}

@media screen and (max-width: 700px) {
  .proposals-navigation {
    padding: 16px 0 16px 16px;
  }
}
