.proposals-info-content {
  width: 96%;
  margin: 0 auto;
  margin-top: 2%;
}

.display-flex-proporty {
  display: block;
}

.custom-write-comment-style,
.custom-write-comment-style:hover,
.custom-write-comment-style:visited,
.custom-write-comment-style:active,
.custom-write-comment-style:focus {
  width: 17%;
  margin-left: 1%;
}

.comment-title-style {
  color: #2e5bff;
  margin-bottom: 2%;
  margin-top: 3%;
}

textarea {
  resize: none !important;
}

.custom-icon-style {
  vertical-align: bottom;
}

/* MisSolicitudesInfoPage styles */

.custom-solicitudes-div-style {
  width: 100%;
}

.custom-solicitudes-info-upper-div-style {
  display: flex;
}

.custom-solicitudes-info-title-div-style {
  width: 70%;
  margin-right: 10%;
}

.custom-silicitudes-info-social-media-div-style {
  width: 20%;
}

@media screen and (max-width: 700px) {
  .boton-comentar {
    font-size: 0;
  }
}
